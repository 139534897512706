import { Widget } from '@lumino/widgets';
import * as monaco from 'monaco-editor';
export class ContentWidget extends Widget {
    static menuFocus;
    static currentWidget = null;
    static previewWidget = null;
    editor = null;
    initialContent;
    fileHandle = null;
    contextMenuHandler = null;
    isPreview = false;
    constructor(name, initialContent = '', fileHandle = null, isPreview = false) {
        super();
        this.setFlag(Widget.Flag.DisallowLayout);
        this.addClass('content');
        this.title.label = name;
        this.title.closable = true;
        this.title.caption = `Editing: ${name}`;
        this.title.iconClass = 'fa fa-file';
        this.initialContent = initialContent;
        this.fileHandle = fileHandle;
        this.isPreview = isPreview;
        this.node.style.width = '100%';
        this.node.style.height = '100%';
        this.contextMenuHandler = (event) => {
            ContentWidget.menuFocus = this;
        };
        this.node.addEventListener('contextmenu', this.contextMenuHandler);
        if (this.isPreview) {
            this.title.className += ' preview-tab';
        }
    }
    onAfterAttach(msg) {
        super.onAfterAttach(msg);
        let model = null;
        const uri = monaco.Uri.parse('file:///' + (this.fileHandle ? this.fileHandle.name : this.title.label));
        if (monaco.editor.getModel(uri)) {
            model = monaco.editor.getModel(uri);
        }
        else {
            monaco.editor.createModel(this.initialContent, undefined, uri);
            model = monaco.editor.getModel(uri);
        }
        this.editor = monaco.editor.create(this.node, {
            model: model,
            automaticLayout: true,
        });
        if (this.isPreview && this.editor) {
            this.editor.onDidChangeModelContent(() => {
                this.convertToPermanent();
            });
        }
    }
    onResize(msg) {
        super.onResize(msg);
        if (this.editor) {
            this.editor.layout();
        }
    }
    onActivateRequest(msg) {
        if (this.editor) {
            this.editor.focus();
        }
        ContentWidget.currentWidget = this;
    }
    onBeforeDetach(msg) {
        super.onBeforeDetach(msg);
    }
    dispose() {
        if (ContentWidget.menuFocus === this) {
            ContentWidget.menuFocus = null;
        }
        if (ContentWidget.currentWidget === this) {
            ContentWidget.currentWidget = null;
        }
        if (ContentWidget.previewWidget === this) {
            ContentWidget.previewWidget = null;
        }
        if (this.contextMenuHandler) {
            this.node.removeEventListener('contextmenu', this.contextMenuHandler);
            this.contextMenuHandler = null;
        }
        if (this.editor) {
            const model = this.editor.getModel();
            if (model && !model.isDisposed()) {
                model.dispose();
            }
            this.editor.dispose();
            this.editor = null;
        }
        super.dispose();
    }
    getEditorContent() {
        return this.editor ? this.editor.getValue() : '';
    }
    getFileHandle() {
        return this.fileHandle;
    }
    setFileHandle(handle) {
        this.fileHandle = handle;
        this.title.label = handle.name;
        this.title.caption = `Editing: ${handle.name}`;
    }
    getEditor() {
        return this.editor;
    }
    isPreviewMode() {
        return this.isPreview;
    }
    convertToPermanent() {
        if (this.isPreview) {
            this.isPreview = false;
            this.title.className = this.title.className.replace(' preview-tab', '');
            if (ContentWidget.previewWidget === this) {
                ContentWidget.previewWidget = null;
            }
        }
    }
}
